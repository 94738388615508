import './index.scss'
// import '../../assets/scripts/myNiceScroll'
// import {resizeScroll} from '../../assets/scripts/myNiceScroll'
import Swiper from 'swiper/swiper-bundle.min.js'

var indexswiper = new Swiper(".index-banner .mySwiper", {
  speed: 1000,
  loop: true,
  autoplay: {
    delay: 6000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {
    el: ".index-banner .swiper-pagination",
    clickable: true
  },
  navigation: {
    nextEl: ".index-banner .swiper-button-next",
    prevEl: ".index-banner .swiper-button-prev",
  },
  on: {
    init: function(swiper){
      $('.index-banner .swiper-mypagination .totalNum').html(this.slides.length - 2)
    },
    slideChangeTransitionStart: function(){
      $('.index-banner .swiper-mypagination .activeNum').html(this.realIndex + 1)
    },
  },
});